export default [
  /* */
  { name: '黑馬補漲', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '您終究要住別墅', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:-60,mobileOffset:0,  type: '' },
  { name: '新光特區', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:-60,mobileOffset:0, type: '' },
  { name: '輕歐式別墅', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '麗寶承諾', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  // { name: '樣品屋預留', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60, mobileOffset:0 , type: '' },
  
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-60,mobileOffset:0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
]