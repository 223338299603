import { isMobile } from '../utils/index'
export default {
  address: '台中市太平區立功路182號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7281.02115119459!2d120.7144321!3d24.1538203!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x695ba004dc75cbc!2z5b6u56yR6I6K5ZyS!5e0!3m2!1szh-TW!2stw!4v1666334970905!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/vH4nH8VPRyD1aA6K6',
  phone: '04-2398-1111',
  fbLink: 'https://www.facebook.com/PC.SMILECASTLE',
  fbMessage: 'https://m.me/PC.SMILECASTLE',
  caseName: '微笑莊園',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '麗寶集團 鵬程建設',],
    ['營造公司', '安生營造',],
    ['建築設計', '陳明偉建築師',],
    ['使用執照', '110中都使字第00490號',],
    ['行銷企劃', '海沃創意行銷',],
  ],

  gtmCode: ['N4QBMF9'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
  //  room_type: ['兩房','三房'],//房型
  },
  lixin_logo:true, //false

}